<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="waitingListStore.waitingListItem.d_nameLast + ', ' +
      waitingListStore.waitingListItem.d_nameFirst + ' - ' +
      waitingListStore.waitingListItem.d_wlsID" />

      <!--Function Bar-->
      <waiting-list-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

          <v-col cols="12" sm="12" md="9">
            <!--Correspondence List-->
            <correspondence :key="waitingListStore.waitingListItem.id" :corresSection="5" />
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <notes :key="waitingListStore.waitingListItem.id" :noteSection="8" />
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import WaitingListFunctionBar from '@/components/WaitingListFunctionBar';
import Correspondence from '@/components/Correspondence';
import Notes from '@/components/Notes';

export default {
  name: 'WaitingListItemCorrespondence',
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: (state) => state.teachersStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  components: {
	  TitleBar,
	  WaitingListFunctionBar,
    Correspondence,
    Notes,
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('teachersStore/read', this.$route.params.teacherUUID);
      this.$store.dispatch('progressStore/set', false);
	  },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
